.leftMargin {
  margin-left: 24px;
}

.checkFlexAgent {
  display: flex;
  margin-left: 24px;
}

.checkboxFlexAgent {
  margin-right: 12px;
  width: 1.5rem;
  height: 1.5rem;
}

.titleFlexAgent {
  font-weight: 600;
  margin-bottom: 10px;
}
