.root {
  form {
    display: flex;

    .uploadStep {
      min-width: 300px;
      margin: 0 1rem;
    }
  }

  .step {
    margin: 1rem 0;
    font-weight: 600;
  }

  *:focus {
    outline: none;
  }
  .header {
    display: flex;
    img {
      margin-left: 0.5rem;
    }
  }
}
