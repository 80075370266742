.option {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

.warning {
  border-top: 1px solid #cccccc;
  padding-top: 18px;
  color: #dd2d30;

  img {
    width: 17px;
    margin-left: 4px;
  }
}
.cancel {
  background-color: #dd2d30;
}
.showMore button {
  padding: 0 0 3px 0;
  margin: 0;
  font-size: 1rem;
}
a {
  color: #0071b7;
}
